.blog-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}
.blog-card-header img {
  width: 100%;
  object-fit: cover;
}
.blog-card-body {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  color: black !important;
  & h4 {
    font-size: 20px;
    text-align: left;
  }
}
.blog-card-body-tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.blog-tag {
  background-color: #ccc;
  color: #fff;
  font-weight: 500;
  border-radius: 50px;
  font-size: 11px;
  margin: 0;
  padding: 4px 12px;
  text-transform: uppercase;
}
.blog-tag-default {
  background-color: #232323;
}
.blog-tag-teal {
  background-color: #92d4e4;
}
.blog-tag-purple {
  background-color: #3d1d94;
}
.blog-tag-pink {
  background-color: #c62bcd;
}
.blog-tag-gold {
  background-color: #caab00;
}
.blogs-tag-reddish {
  background-color: #ca0018;
}
.blogs-tag-greenish {
  background-color: #2fb10e;
}
.blog-card-body h4 {
  margin: 10px 0;
}
.blog-card-body p {
  font-size: 14px;
  margin: 0 0 40px 0;
  font-weight: 500;
  color: rgb(70, 68, 68);
}
.blog-skeleton {
  display: block;
  height: 170.8px;
}
