.home-section {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  &__select-text {
    margin-top: 20px;
    font: italic small-caps 300 3.5rem Impact;
    @include respond(tab-land) {
      font: italic small-caps 300 4.5rem Impact;
    }
  }
}
