@import "mixins";
@import "/src/components/SkillCard/skillcards.scss";
@import "/src/components/filter/filter.scss";
@import "/src/components/blog-card/blog-card.scss";
@import "/src/components/contact-form/contact-form.scss";
@import "/src/styles/services-section.scss";
@import "/src/styles/home-section.scss";
@import "/src/styles/contact-section.scss";
@import "/src/styles/blogs-section.scss";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  color: white;
  background-attachment: fixed !important;
  background-image: linear-gradient(to top, #1f1d1d 40%, black 100%);
}
html {
  font-size: 62.5%; //to speciy rem
  max-width: 100%;
  overflow-x: hidden;

  @include respond(tab-land) {
    font-size: 56.25%;
  }
  @include respond(tab-port) {
    font-size: 50%;
  }
  @include respond(big-desktop) {
    font-size: 75%;
  }
}
h1 {
  font-size: 5rem;
  text-transform: uppercase;
  @include respond(phone) {
    font-size: 3.5rem;
  }
}
h2 {
  opacity: 0.7;
  font-size: 3rem;
  @include respond(phone) {
    font-size: 2.5rem;
  }
}
h3 {
  font-size: 2.5rem;
  opacity: 0.7;
}
a {
  text-decoration: none;
}
li,
p,
q,
i {
  font-size: 2rem;
  line-height: 2em;
  word-wrap: break-word;
  font-weight: 450;
  text-align: left;
  font-family: Open Sans, Arial, sans-serif;
}
.container_notFound_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 30px;
  align-items: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.skeleton {
  height: 100%;
  width: 100%;
  border-radius: 30px 30px 0px 0px !important;
}

.skeletonLogoCards {
  height: 82%;
  opacity: 0.2;

  @include respond(tab-port) {
    height: 75%;
  }
}

.mainContainer {
  height: 100vh;
  width: 100vw;
  display: inline-block;
}
.alertforblogs {
  padding: 30px;
  background-color: rgb(114, 255, 86, 0.2);
  border-radius: 50px;
  & h2 {
    opacity: 1;
  }
}
.twodContainer_sub {
  padding: 130px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  @media (max-width: 900px) {
    padding: 130px 30px 50px 30px;
  }
  @media (max-width: 380px) {
    padding: 80px 30px;
  }
}
.threedContainer {
  max-height: 700px;

  height: 100%;
  @media (max-width: 320px) {
    max-height: 1500px;
  }
}

.copyrightSection {
  opacity: 0.6;
  text-align: center;
  height: 50px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  @include respond(tab-port) {
    font-size: 10px;
  }
}
.blogcontainer {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 150px;
  padding: 0px 30px;

  h2,
  h3 {
    text-align: left;
  }
  @include respond(tab-land) {
    margin-top: 100px;
    & h3 {
      font-size: 2.5rem;
    }
  }
  @include respond(phone) {
    margin-top: 75px;
  }
  & a {
    color: white;
    overflow: hidden;
    opacity: 0.8;
    color: #2ea3f2;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.seperator {
  height: 50px;
  @include respond(tab-land) {
    height: 45px;
  }
  @include respond(phone) {
    height: 35px;
  }
}
.twocolumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  @include respond(phone) {
    grid-template-columns: auto;
  }
}
img {
  width: 100%;
  height: auto;
}
input[type="checkbox"] {
  accent-color: black;
}

button,
.button {
  display: inline-block;
  color: #fff0ec !important;
  border-width: 0px !important;
  border-radius: 0px;
  letter-spacing: 2px;
  font-size: 11px;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  background-color: #000000;
  padding: 30px 50px;
  transition: all 0.2s ease-in-out;

  @include respond(phone) {
    font-size: 7px;
    padding: 20px 40px;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  & a {
    color: white !important;
  }
}
.aligncenter {
  align-items: center;
}
.video {
  width: 100%;

  height: 600px;
  @include respond(tab-land) {
    height: 500px;
  }
  @include respond(tab-port) {
    height: 400px;
  }
  @include respond(phone) {
    height: 300px;
  }
  @include respond(small-phone) {
    height: 200px;
  }
}

/* Define styles for the scrollbar track */
::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  background-color: #000; /* Set the background color of the track */
}

/* Define styles for the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #555; /* Set the color of the thumb */
  border-radius: 5px; /* Add rounded corners to the thumb */
}

/* Define styles for the scrollbar thumb on hover (optional) */
::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* Change the color on hover */
}
