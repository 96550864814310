.skill__buttons {
  font-size: 40px;
  padding: 10px;
  width: 80px;
  height: 80px;
  border-radius: 10%;
  box-shadow: rgb(0 0 0 / 100%) 0px 3px 10px -3px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include respond(phone) {
    height: 60px;
    width: 60px;
  }
}

.skill__buttons svg {
  margin-top: -10px;
}

.button-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.button-wrapper > div {
  margin: 0 auto;
}

.skill__buttons {
  position: relative;
}

.skills__button--d-block {
  transform: scale(0);
  transition: all 500ms ease 0s;
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  bottom: 5px;
  @include respond(phone) {
    font-size: 10px;
    bottom: 2.5px;
  }
}

.skill__buttons:hover .skills__button--d-block {
  transform: scale(1);
  transition: all 300ms ease 0s;
}

.skill__buttons svg {
  transform: scale(1);
  transition: all 300ms ease 0s;
}

.skill__buttons:hover svg {
  transform: scale(0.9);
  filter: brightness(0.8);
  transition: all 300ms ease 0s;
}
