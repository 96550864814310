.blogs-section {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 50px;
  @include respond(tab-port) {
    max-width: 500px;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    width: 100%;
    margin: auto;
    @media (max-width: 940px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }
  }
  &__year {
    margin: 40px 0px;
  }
}
