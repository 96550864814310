.contact-form {
  max-width: 500px;
  width: 100%;
  height: fit-content;
  border-radius: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 30px;
  padding: 50px 60px;
  color: black;
  font-size: 20px;

  @include respond(phone) {
    padding: 50px 40px;
  }
  & input {
    border: none;
    align-items: stretch;
    outline: none;
    padding: 10px;
    font-family: Arial, sans-serif;
    border-radius: 2px;
    border: 1px solid rgb(31, 29, 29, 0.6);
  }

  & textarea {
    resize: none;
    border: none;
    outline: none;
    align-items: stretch;
    font-family: Arial, sans-serif;
    padding: 10px;
    border-radius: 2px;
    width: 100% !important;
    border: 1px solid rgb(31, 29, 29, 0.6);
  }
  & input::placeholder,
  textarea::placeholder {
    font-family: Arial, sans-serif; /* Should match the font-family of input and textarea */
  }

  &__request-cv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  &__checkbox {
    width: 20px;
    height: 20px;
    @include respond(phone) {
      width: 16px;
      height: 16px;
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  &--alignCenter {
    align-self: center;
  }
  &--error {
    color: rgb(223, 11, 11);
    text-align: center;
    font-size: 15px;
    overflow: hidden;
  }
  &--success {
    color: rgb(11, 223, 82);
    text-align: center;
    font-size: 15px;
  }
  &__button {
    font-size: 15px;
    border-radius: 50%;
    background-color: #1f1d1d;
    color: white;
    padding: 17px;
    align-self: center;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    &--disabled {
      opacity: 0.5;
      &:hover {
        cursor: default;
      }
    }
    &--loading {
      &:hover {
        cursor: default;
      }
    }
    &--hoverable {
      &:hover {
        cursor: pointer;
        transform: scale(105%);
      }
    }
  }
}
