.services-container {
  max-width: 1000px;
  width: 100%;

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 50px;
    text-align: center;
  }
  &__github {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    @include respond(tab-land) {
      flex-direction: column;
      gap: 15px;
    }
  }
  &__skill-stack {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-items: center;
    margin-bottom: 40px;
    @include respond(tab-land) {
      grid-template-columns: repeat(6, 1fr);
    }
    @include respond(tab-port) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 380px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__projects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
  }
  & a {
    color: white;
    overflow: hidden;
    opacity: 0.8;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}
