.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  button {
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    color: black !important;
    cursor: pointer;
    opacity: 0.8;

    @include respond(phone) {
      font-size: 9px;
    }
    &.active {
      opacity: 1;
    }
  }
}
