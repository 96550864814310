@import "../../mixins";

.logoCard {
  align-items: center;
  border-radius: 10%;
  box-shadow: 0 3px 10px -3px rgb(0 0 0 / 54%);

  font-size: 40px;

  justify-content: center;
  height: 80px;
  width: 80px;
  opacity: 0.9 !important;

  display: inline-block;
  position: relative;
  @include respond(tab-port) {
    height: 60px;
    width: 60px;
  }
  &:hover {
    opacity: 1 !important;
  }
  & .logoImage {
    transform: scale(0.9);
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1);
      opacity: 0.8;
    }
  }
}
