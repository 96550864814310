.contact-section {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;

  &__socials {
    display: flex;
    gap: 40px;
    overflow: hidden;
    font-size: 60px;
    &--blogs {
      justify-content: center;
    }
    @include respond(phone) {
      font-size: 30px;
    }
    @include respond(small-phone) {
      gap: 20px;
    }
    & a {
      color: white !important;
      overflow: hidden;
      opacity: 0.8;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
